@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.dropDownWrap {
  position: relative;

  .choicesContainer {
    // position: absolute;
    // width: 100%;
    z-index: 5;
    position: absolute;
    top: 100%; /* Place below the nav item */
    left: 50%; /* Start positioning from the center */
    transform: translateX(-50%); /* Center align */

    :global(.form-sub-group.input-option) {
      // center
      display: flex;
      justify-content: center;
      font-family: $font-sans;
      font-size: 16px;
      text-transform: uppercase;
      color: $color-black;
      border-radius: 0;
      background-color: $color-salmon;

      :global(.input-icon--left) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #ABB8C3;

        svg {
          display: none
        }
      }

      @include breakpoint(desktop-screen) {
        font-size: 10px;
        display: flex;
        justify-content: right;
        background-color: $color-black;
        color: $color-white;
        // width: 300%;
        min-width: 150px;
        padding: 10px 20px 10px 60px;
      }

      &[data-selected=true] {
        color: $color-black;

        :global(.input-icon--left) {
          border: none;

          svg {
            display: block;
          }
        }
      }
    }

    :global(.form-sub-group.input-option:hover) {
      color: $color-white;
      background-color: $color-black;

      :global(.input-icon--left) {
        border: none;

        svg {
          display: block;
        }
      }
    }
  }


}
